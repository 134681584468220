import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppService, NavService } from '@softbrik/data/services';
import { Brik } from '@softbrik/data/models';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { JoyrideService } from 'ngx-joyride';
import Bugsnag from '@bugsnag/browser';
import { take } from 'rxjs/operators';

@Component({
  selector: 'softbrik-page',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public session = this.app.session.scope('dashboard');
  public store = this.app.store.scope('dashboard');
  public briksAll: Observable<Brik[]>;
  public briks: Observable<Brik[]>;
  public briksAdmin: Observable<Brik[]>;
  public customerName = '';
  private tourSubscription: Subscription;

  constructor(
    public app: AppService,
    public nav: NavService,
    public title: Title,
    public translate: TranslateService,
    private readonly joyrideService: JoyrideService
  ) {}

  public t(key: string) {
    return this.translate.get(key).toPromise();
  }

  ngOnInit() {
    this.setCustomer();
    this.loadBriks();
    this.nav.clearSidebar();
  }

  ngOnDestroy(): void {
    if (this.tourSubscription) {
      this.tourSubscription.unsubscribe();
    }
  }

  loadBriks() {
    this.app.is_loading = false;
    this.briksAll = this.app.getUserBriks();
    this.briks = this.app.getUserCommonBriks();
    this.briksAdmin = this.app.getUserAdminBriks();

    this.briksAll.pipe(take(1)).subscribe(() => {
      const onboarded = this.store.getItem('tour');
      if (!onboarded) {
        // Wait for images to load before starting tour
        setTimeout(this.startTour.bind(this), 1000);
      }
    });
  }

  async startTour() {
    this.app.analytics.emit({
      name: 'tour: start',
      payload: {
        tour: 'dashboard',
      },
    });
    let lastStep: string;
    const steps = ['trust', 'survey', 'stak', 'admin'];
    this.tourSubscription = this.joyrideService
      .startTour({
        logsEnabled: false,
        showCounter: true,
        showPrevButton: false,
        waitingTime: 150,
        customTexts: {
          close: await this.t('joyride.close'),
          done: await this.t('joyride.done'),
          next: await this.t('joyride.next'),
          prev: await this.t('joyride.prev'),
        },
        steps,
      })
      .subscribe(
        (step) => {
          lastStep = step.name;
          this.app.analytics.emit({
            name: 'tour: step',
            payload: {
              tour: 'dashboard',
              step: step.name,
              number: step.number,
            },
          });
        },
        (error) => {
          Bugsnag.notify(error);
        },
        () => {
          this.app.analytics.emit({
            name: 'tour: end',
            payload: {
              tour: 'dashboard',
              lastStep: lastStep,
              completed: lastStep === steps[steps.length - 1],
            },
          });
          this.store.setItem('tour', true);
        }
      );
  }

  setCustomer() {
    const { customer_alias } = this.app.decodeToken();
    this.customerName =
      customer_alias[0].toUpperCase() + customer_alias.substring(1);
  }

  async openBrik(brikId: string) {
    this.app.analytics.emit({
      name: 'app nav: open brik',
      payload: { brik: brikId },
    });
    this.setTitle(`Softbrik - ${await this.t(`briks.${brikId}`)}`);
    this.app.store.setItem('brik', brikId);
    if (['contact', 'file', 'stak', 'survey', 'trust'].includes(brikId)) {
      this.app.navigate(brikId);
    } else {
      this.app.navigate(brikId + '/dashboard');
    }
  }

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }
}
