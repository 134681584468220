<div class="dashboard container is-fluid">
  <h3
    class="mt-4 title is-5"
    translate="dashboard.Welcome, {customer}"
    [translateParams]="{ customer: customerName }"
    (click)="startTour()"
  ></h3>

  <ng-template #loading>
    <div class="dashboard__loader animate-pulse" translate>common.Loading</div>
  </ng-template>

  <div *ngIf="briksAll | async; else loading" class="briks">
    <div class="briks__group">
      <ng-container *ngFor="let brik of briks | async">
        <softbrik-brik-card
          (click)="openBrik(brik.brik_id)"
          [joyrideStep]="brik.brik_id"
          [brikid]="brik.brik_id"
          [briktitle]="'briks.' + brik.description | translate"
          [title]="
            'joyride.tours.dashboard.' + brik.brik_id + '.title' | translate
          "
          [text]="
            'joyride.tours.dashboard.' + brik.brik_id + '.text' | translate
          "
          [stepPosition]="brik.brik_id === 'trust' ? 'right' : 'bottom'"
        >
        </softbrik-brik-card>
      </ng-container>
    </div>

    <hr />

    <div class="briks__group">
      <softbrik-brik-card
        *ngFor="let brik of briksAdmin | async"
        (click)="openBrik(brik.brik_id)"
        [joyrideStep]="brik.brik_id"
        [brikid]="brik.brik_id"
        [briktitle]="'briks.' + brik.description | translate"
        [title]="
          'joyride.tours.dashboard.' + brik.brik_id + '.title' | translate
        "
        [text]="'joyride.tours.dashboard.' + brik.brik_id + '.text' | translate"
        stepPosition="right"
      >
      </softbrik-brik-card>
    </div>
  </div>
</div>
